import { Nullable } from '@ac/library-api';
import {
  createAction,
  getOneAction,
  updateAction,
} from '@ac/library-utils/dist/redux-utils';

import { KioskRegCardDetails } from 'api/KioskApi/entries';

import { SagaError } from 'types/shared';

import { InitialStateData, UpdateSummaryDataPayload } from './interfaces';

export const receivedRegistrationCardForDevice = createAction<string>(
  '@electronicRegistrationProcess/receivedRegistrationCardForDevice'
);

export const fetchRegistrationCardDetails = getOneAction<
  string,
  KioskRegCardDetails,
  SagaError
>('@electronicRegistrationProcess/fetchRegistrationCardDetails');

export const clearElectronicRegistrationProcess = createAction(
  '@electronicRegistrationProcess/clearElectronicRegistrationProcess'
);

export const cancelElectronicRegistrationProcess = updateAction<
  unknown,
  unknown,
  SagaError
>('@electronicRegistrationProcess/cancelElectronicRegistrationProcess');

export const startElectronicRegistrationProcess = updateAction<
  undefined,
  string,
  SagaError
>('@electronicRegistrationProcess/startElectronicRegistrationProcess');

export const cacheCheckedConsents = createAction<{ [key: string]: boolean }>(
  '@electronicRegistrationProcess/cacheCheckedConsents'
);

export const cachePurposeOfStay = createAction<string>(
  '@electronicRegistrationProcess/cachePurposeOfStay'
);

export const cacheEtd = createAction<string>(
  '@electronicRegistrationProcess/cacheEtd'
);

export const updateSummaryData = updateAction<
  UpdateSummaryDataPayload,
  undefined,
  SagaError
>('@electronicRegistrationProcess/updateSummaryData');

export const uploadSignedCard = updateAction<Blob, undefined, SagaError>(
  '@electronicRegistrationProcess/uploadSignedCard'
);

export const completeRegistrationCard = createAction(
  '@electronicRegistrationProcess/completeRegistrationCard'
);

export const anotherSessionOpenedInNewTab = createAction(
  '@electronicRegistrationProcess/anotherSessionOpenedInNewTab'
);

export const setInitialStateData = createAction<Nullable<InitialStateData>>(
  '@electronicRegistrationProcess/setInitialStateData'
);
