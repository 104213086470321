import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { TimePickerField } from '@ac/kiosk-components';
import { FieldRenderProps, formFieldFactory } from '@ac/react-infrastructure';

import { getDateTimeFormats } from 'store/settings/selectors';
import { mapFieldRenderProps } from 'utils/form';

import { FormProperties, FormValues } from '../../types';

import './EtdField.scss';

const FormTimeField = formFieldFactory<Pick<FormValues, FormProperties.etd>>();

interface EtdFieldProps {
  id?: string;
  dataTestSelector?: string;
  disabled?: boolean;
  className?: string;
}

export const EtdField = ({
  id,
  dataTestSelector,
  disabled,
  className,
}: EtdFieldProps): JSX.Element => {
  const [t] = useTranslation();
  const dateTimeFormats = useSelector(getDateTimeFormats);

  return (
    <FormTimeField valuePath={FormProperties.etd}>
      {(fieldRenderProps: FieldRenderProps<string>): JSX.Element => (
        <TimePickerField
          {...mapFieldRenderProps(fieldRenderProps)}
          id={id}
          className={classNames('reg-card-etd-edit', className)}
          dataTestSelector={dataTestSelector}
          label={t('REGISTRATION_CARD.ETD')}
          placeholder={t('SHARED.SELECT')}
          disabled={disabled}
          fieldDisplayFormat={dateTimeFormats?.timeFormat}
        />
      )}
    </FormTimeField>
  );
};
