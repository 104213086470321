var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .purpose-of-stay-subtitle {\n    font-size: 10px;\n    margin: 0;\n  }\n\n  .purpose-of-stay-data {\n    font-size: 12px;\n    margin: 0;\n    margin-top: 4px;\n  }\n</style>\n\n<div>\n"
    + ((stack1 = container.invokePartial(require("./textGroup.hbs"),depth0,{"name":"textGroup","hash":{"value":(depth0 != null ? lookupProperty(depth0,"data") : depth0),"label":(depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});