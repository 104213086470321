import { ReducerMap } from 'redux-actions';

import { ApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import { MissingPermissionsError } from 'utils/customErrors';

import { clearAllGlobalErrors } from '../globalActions';

import * as actions from './actions';
import { Settings, SettingsState } from './interfaces';

const initialState: SettingsState = {
  areSettingsInitialized: false,
  property: undefined,
  entities: undefined,
  general: undefined,
  images: undefined,
  customMessages: undefined,
  reservationHeaderDefinition: undefined,
  styles: undefined,
  errors: [],
  fetching: {
    settings: false,
    refetchingConfiguration: false,
    changeAppLanguage: false,
  },
};

const reducerMap: ReducerMap<SettingsState, unknown> = {
  [actions.applySetup]: (state, action: Action<Settings>) => {
    return {
      ...state,
      ...action.payload,
      areSettingsInitialized: true,
    };
  },

  [actions.prepareSetup.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        settings: true,
      },
    };
  },

  [actions.prepareSetup.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        settings: false,
      },
    };
  },

  [actions.prepareSetup.failure]: (state, action: Action<Error>) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        settings: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.updateBusinessDate]: (state, action: Action<string>) => {
    return {
      ...state,
      property: {
        ...state.property,
        businessDate: action.payload,
      },
    };
  },

  [actions.refetchConfiguration.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        refetchingConfiguration: true,
      },
    };
  },
  [actions.refetchConfiguration.done]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        refetchingConfiguration: false,
      },
    };
  },
  [actions.changeAppLanguage.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeAppLanguage: true,
      },
    };
  },

  [actions.changeAppLanguage.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeAppLanguage: false,
      },
    };
  },

  [actions.changeAppLanguage.failure]: (
    state,
    action: Action<ApiError | Error | MissingPermissionsError>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        changeAppLanguage: false,
      },
      errors: [...state.errors, action.payload],
    };
  },
  [clearAllGlobalErrors]: (state) => {
    return {
      ...state,
      errors: [],
    };
  },
} as ReducerMap<SettingsState, unknown>;

export const settings = handleActions<SettingsState>(reducerMap, initialState);
