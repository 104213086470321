import { Action, ReducerMap } from 'redux-actions';

import { ApiError, Nullable } from '@ac/library-api';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import { KioskRegCardDetails } from 'api/KioskApi/entries';

import { clearAllGlobalErrors } from '../globalActions';

import * as actions from './actions';
import {
  ElectronicRegistrationProcessState,
  InitialStateData,
} from './interfaces';

const initialState: ElectronicRegistrationProcessState = {
  registrationCardId: undefined,
  fetching: {
    start: false,
    regCardDetails: false,
    regCardCancelling: false,
    uploadSignedCard: false,
  },
  processId: undefined,
  regCardDetails: undefined,
  regCardCancelled: false,
  regCardCompleted: false,
  regCardAnotherSessionOpenedInNewTab: false,
  cachedAppliedConsents: undefined,
  cachedPurposeOfStay: undefined,
  cachedEtd: undefined,
  initialStateData: null,
  errors: [],
};

const reducerMap: ReducerMap<ElectronicRegistrationProcessState, unknown> = {
  [actions.receivedRegistrationCardForDevice]: (
    state,
    action: Action<string>
  ) => {
    return {
      ...state,
      registrationCardId: action.payload,
    };
  },

  [actions.fetchRegistrationCardDetails.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        regCardDetails: true,
      },
    };
  },

  [actions.fetchRegistrationCardDetails.success]: (
    state,
    action: Action<KioskRegCardDetails>
  ) => {
    const consents = action.payload.profile.consents;

    return {
      ...state,
      fetching: {
        ...state.fetching,
        regCardDetails: false,
      },
      regCardDetails: action.payload,
      cachedAppliedConsents: state.cachedAppliedConsents || {
        ...(consents &&
          Object.fromEntries(
            consents.map((item) => [item.consentId, item.isGranted])
          )),
      },
      cachedPurposeOfStay:
        state.cachedPurposeOfStay ||
        action.payload.reservation.purposeOfStay?.id,
      cachedEtd:
        state.cachedEtd || action.payload.reservation.etd?.split('T')[1],
    };
  },

  [actions.fetchRegistrationCardDetails.failure]: (
    state,
    action: Action<Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        regCardDetails: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.startElectronicRegistrationProcess.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        start: true,
      },
    };
  },

  [actions.startElectronicRegistrationProcess.success]: (
    state,
    action: Action<string>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        start: false,
      },
      processId: action.payload,
    };
  },

  [actions.startElectronicRegistrationProcess.failure]: (
    state,
    action: Action<Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        start: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.cancelElectronicRegistrationProcess.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        regCardCancelling: true,
      },
    };
  },

  [actions.cancelElectronicRegistrationProcess.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        regCardCancelling: false,
      },
      regCardCancelled: true,
    };
  },

  [actions.cancelElectronicRegistrationProcess.failure]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        regCardCancelling: false,
      },
      regCardCancelled: false,
    };
  },

  [actions.clearElectronicRegistrationProcess]: () => {
    return {
      ...initialState,
    };
  },

  [clearAllGlobalErrors]: (state) => {
    return {
      ...state,
      errors: [],
    };
  },

  [actions.cacheCheckedConsents]: (
    state,
    action: Action<{ [key: string]: boolean }>
  ) => {
    return {
      ...state,
      cachedAppliedConsents: action.payload,
    };
  },

  [actions.cachePurposeOfStay]: (state, action: Action<string>) => {
    return {
      ...state,
      cachedPurposeOfStay: action.payload,
    };
  },

  [actions.cacheEtd]: (state, action: Action<string>) => {
    return {
      ...state,
      cachedEtd: action.payload,
    };
  },

  [actions.updateSummaryData.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateConsentsAndPurposeOfStay: true,
      },
    };
  },

  [actions.updateSummaryData.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateConsentsAndPurposeOfStay: false,
      },
    };
  },

  [actions.updateSummaryData.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateConsentsAndPurposeOfStay: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.uploadSignedCard.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        uploadSignedCard: true,
      },
    };
  },

  [actions.uploadSignedCard.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        uploadSignedCard: false,
      },
    };
  },

  [actions.uploadSignedCard.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        uploadSignedCard: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.completeRegistrationCard]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
      },
      regCardCompleted: true,
    };
  },

  [actions.anotherSessionOpenedInNewTab]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
      },
      regCardAnotherSessionOpenedInNewTab: true,
    };
  },

  [actions.setInitialStateData]: (
    state,
    action: Action<Nullable<InitialStateData>>
  ) => {
    return {
      ...state,
      initialStateData: action.payload,
    };
  },
};

export const electronicRegistrationProcess = handleActions<ElectronicRegistrationProcessState>(
  reducerMap,
  initialState
);
