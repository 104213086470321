import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  ButtonPattern,
  Section,
  SectionType,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { KioskRegCardDetails } from 'api/KioskApi/entries';
import {
  getAddressTypeEntities,
  getCommunicationModeOrder,
  getCommunicationTypeEntities,
  getCustomMessages,
  getDateTimeFormats,
  getGeneralSettings,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import {
  mapAddressData,
  mapContactsData,
  mapDocumentsData,
  mapPersonalData,
} from 'utils/regCardPresentationDataMappers';

import { PersonalNestedSection } from './PersonalNestedSection/PersonalNestedSection';

import './PersonalDetailsSection.scss';

interface PersonalDetailsSectionProps {
  cardDetails: KioskRegCardDetails;
  editButtonVisible?: boolean;
  enableCollapsible?: boolean;
  showOnlyPrimary?: boolean;
  onEditButtonClick?: () => void;
}

export const PersonalDetailsSection = ({
  cardDetails,
  editButtonVisible,
  enableCollapsible,
  showOnlyPrimary,
  onEditButtonClick,
}: PersonalDetailsSectionProps): JSX.Element => {
  const [t, i18next] = useTranslation();
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const generalSettings = useSelector(getGeneralSettings);
  const communicationModeOrder = useSelector(getCommunicationModeOrder);
  const customMessages = useSelector(getCustomMessages);
  const communicationTypes = useSelector(getCommunicationTypeEntities);
  const addressTypes = useSelector(getAddressTypeEntities);
  const dateTimeFormats = useSelector(getDateTimeFormats);

  const {
    addresses,
    identityDocuments,
    emails,
    mobiles,
    phones,
  } = cardDetails.profile;

  const personalInfo = useMemo(
    () =>
      mapPersonalData(cardDetails, {
        isMiddleNameEnabled: generalSettings?.MIDDLENAME,
        isSuffixEnabled: generalSettings?.SUFFIX,
        shortDateFormat: dateTimeFormats?.shortDateFormat,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cardDetails,
      generalSettings?.MIDDLENAME,
      generalSettings?.SUFFIX,
      dateTimeFormats?.shortDateFormat,
      i18next.language,
    ]
  );

  const addressDetails = useMemo(
    () =>
      mapAddressData(addresses, addressTypes, {
        hideDistrict: !generalSettings?.DISTRICT,
        showOnlyPrimary,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addresses, addressTypes, i18next.language]
  );

  const contacts = useMemo(() => {
    return mapContactsData(
      { phones, mobiles, emails },
      {
        modeOrder: communicationModeOrder,
        isEmailEnabled: generalSettings?.DISPLAY_EMAIL,
        isMobileEnabled: generalSettings?.DISPLAY_MOBILE,
        isPhoneEnabled: generalSettings?.DISPLAY_PHONE,
        communicationTypes,
        showOnlyPrimary,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobiles, phones, emails, communicationTypes, i18next.language]);

  const documents = useMemo(
    () =>
      identityDocuments &&
      mapDocumentsData(identityDocuments, {
        shortDateFormat: dateTimeFormats?.shortDateFormat,
        businessDate: propertyConfiguration?.businessDate,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      identityDocuments,
      propertyConfiguration?.businessDate,
      dateTimeFormats?.shortDateFormat,
      i18next.language,
    ]
  );

  const sectionsVisibilityState = useMemo(() => {
    return {
      isAddressDetailsVisible: generalSettings?.DISPLAY_ADDRESS,
      isContactDetailsVisible:
        generalSettings?.DISPLAY_PHONE ||
        generalSettings?.DISPLAY_MOBILE ||
        generalSettings?.DISPLAY_EMAIL,
    };
  }, [generalSettings]);

  return (
    <Section type={SectionType.wide} className="reg-card-personal-section">
      {customMessages?.GREETINGS_MESSAGE && (
        <Text
          size={TextSize.xlg}
          dataTestSelector="address-details-greetings-message"
        >
          {customMessages?.GREETINGS_MESSAGE}
        </Text>
      )}
      <div className="reg-card-personal-section-wrapper">
        <PersonalNestedSection
          title={t('COMPONENTS.PERSONAL_DETAILS_SECTION.PERSONAL')}
          className="reg-card-standard-section"
          data={personalInfo}
          enableCollapsible={enableCollapsible}
        />
        {sectionsVisibilityState.isAddressDetailsVisible && (
          <PersonalNestedSection
            dataTestSelector="address-details-section"
            title={t('SHARED.ADDRESS')}
            noDataInfo={t('SHARED.NO_ADDRESS')}
            className="reg-card-standard-section reg-card-address-section"
            data={addressDetails}
            withSeparators
            enableCollapsible={enableCollapsible}
          />
        )}

        {sectionsVisibilityState.isContactDetailsVisible && (
          <PersonalNestedSection
            dataTestSelector="contact-details-section"
            title={t('COMPONENTS.PERSONAL_DETAILS_SECTION.CONTACT')}
            noDataInfo={t('SHARED.NO_CONTACT')}
            className="reg-card-wrappable-section"
            data={contacts}
            enableCollapsible={enableCollapsible}
          />
        )}
        <PersonalNestedSection
          title={t('COMPONENTS.PERSONAL_DETAILS_SECTION.ID_AND_DOCUMENTS')}
          noDataInfo={t('SHARED.NO_DOCUMENT')}
          className="reg-card-wrappable-section"
          data={documents}
          withSeparators
          enableCollapsible={enableCollapsible}
        />
      </div>
      {editButtonVisible && (
        <Button
          dataTestSelector="edit-personal-details-button"
          className="reg-card-personal-edit-button"
          pattern={ButtonPattern.secondary}
          onClick={onEditButtonClick}
        >
          {t('SHARED.EDIT')}
        </Button>
      )}
    </Section>
  );
};
